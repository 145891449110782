<template>
  <div class="sidebar">
    <h3 class="green">Frequently Asked Questions - FAQ</h3>
    <hr class="dashed" />

    <div class="accordion">
      <div v-for="(item, index) in content" :key="item.id">
        <AccordionElement :item="item" />
        <hr
          v-if="index < content.length - 1"
          class="dashed"
          style="margin: 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import AccordionElement from "./AccordionElement.vue";

export default {
  components: { AccordionElement },
  setup() {
    const content = ref([
      {
        active: false,
        title: "How to make an order?",
        details:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate",
      },
      {
        active: false,
        title: "Celebration",
        details: `
      <p>Come on, this is a Bluth family celebration. It's no place for children.</p>
    `,
      },
      {
        active: false,
        title: "Lighter Fluid and Wine",
        details: `
      <p>But where did the lighter fluid come from? Wine only turns to alcohol if you let it sit. But anyhoo, can you believe that the only reason the club is going under is because it's in a terrifying neighborhood?</p>
    `,
      },
      {
        active: false,
        title: `What's in Oscar's box?`,
        details: `
      In fact, it was a box of Oscar's legally obtained medical marijuana. Primo bud. Real sticky weed.
      
      Great, now I'm gonna smell to high heaven like a tuna melt!
    `,
      },
    ]);

    return { content };
  },
};
</script>
