<template>
  <div
    :id="item.id"
    class="accordion-item"
    :class="{ 'is-active': item.active }"
  >
    <div class="accordion-item-title">
      <button @click="toggle" class="accordion-item-trigger">
        <h3>{{ item.title }}</h3>
        <span class="accordion-item-trigger-icon"></span>
      </button>
    </div>

    <transition
      name="accordion-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <div v-if="item.active" class="accordion-item-details">
        <div v-html="item.details" class="accordion-item-details-inner"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { toRefs } from "@vue/reactivity";
export default {
  props: {
    item: {
      default: "",
    },
  },
  setup(props) {
    const { item } = toRefs(props);
    const toggle = () => {
      item.value.active = !item.value.active;
    };
    const startTransition = (el) => {
      el.style.height = el.scrollHeight + "px";
    };
    const endTransition = (el) => {
      el.style.height = "";
    };
    return { toggle, startTransition, endTransition };
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  &-details-inner {
    padding: 0 8px 7px;
  }

  &-title {
    position: relative;
  }

  &-trigger {
    width: 100%;
    padding: 12px 8px;
    border: none;
    text-align: left;
    background-color: transparent;

    &-icon {
      $size: 8px;

      display: block;
      position: absolute;
      top: 0;
      right: 1.25rem;
      bottom: 0;
      margin: auto;
      width: $size;
      height: $size;
      border-right: 2px solid #363636;
      border-bottom: 2px solid #363636;
      transform: translateY(-$size / 4) rotate(45deg);
      transition: transform 0.3s ease;

      .is-active & {
        transform: translateY($size / 4) rotate(225deg);
      }
    }
  }

  &-details {
    overflow: hidden;
  }

  &-enter-active,
  &-leave-active {
    will-change: height;
    transition: height 0.2s ease;
  }
  &-enter,
  &-leave-to {
    height: 0 !important;
  }
}
</style>
