<template>
  <div class="mt">
    <div class="mt-head">
      <b>Chat with admins</b>
    </div>

    <div class="mt-body">
      <div class="chat-window">
        <div class="chat-scroller" ref="chatContainer">
          <div v-if="chatting && chatting.length">
            <div v-for="(msg, i) in chatting" :key="i">
              <div v-if="Object.keys(msg).length">
                <div v-if="msg.admin_user" class="chat-container chat-admin">
                  <div class="chat-avatar">
                    <img
                      v-if="msg.admin_user.avatar"
                      :src="`${msg.admin_user.avatar}`"
                      alt=""
                    />
                    <span v-else> A</span>
                  </div>

                  <div class="chat-text">
                    <div class="chat-text-details">
                      <p
                        v-if="msg.admin_user.name"
                        class="chat-text-details-name grey"
                      >
                        {{ msg.admin_user.name }}
                      </p>
                      <p
                        v-if="msg.created_at"
                        class="chat-text-details-date grey"
                      >
                        {{ getTime(msg.created_at) }}
                      </p>
                    </div>
                    <p class="chat-text-area">{{ msg.content }}</p>
                  </div>
                </div>

                <div v-else-if="msg.user" class="chat-container chat-user">
                  <div class="chat-text">
                    <div class="chat-text-details">
                      <p class="chat-text-details-name green">
                        {{ msg.user.name }}
                      </p>
                      <p class="chat-text-details-date grey">
                        {{ getTime(msg.created_at) }}
                      </p>
                    </div>
                    <p class="chat-text-area">{{ msg.content }}</p>
                  </div>

                  <div class="chat-avatar">
                    <img
                      v-if="msg.user.avatar"
                      :src="`${msg.user.avatar}`"
                      alt=""
                    />
                    <span v-else>U</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="chat-window-empty-img">
              <img src="@/modules/assets/bg/keyboard.svg" alt="No chatting" />
            </div>
            <h2 class="grey center">Start chatting</h2>
            <h4 class="grey center">
              You can leave a question here and we will answer you as soon as
              possible.
            </h4>
          </div>
        </div>

        <div class="chat-action">
          <textarea
            v-model="message"
            class="chat-textarea"
            rows="3"
            @keyup.enter="send"
          />
          <button
            class="chat-btn"
            :class="{ active: message.length }"
            :disabled="!message.length"
            @click="send"
          >
            <IconAirplane icon-color="#ffffff" width="26" height="18" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import IconAirplane from "@/modules/components/icons/IconAirplane.vue";

export default {
  components: { IconAirplane },
  setup() {
    const store = useStore();

    store.dispatch("globalMessenger/showChatting");

    const chatContainer = ref(null);
    const message = ref("");
    const chatting = computed(() => store.getters["globalMessenger/chatting"]);
    const user = computed(() => store.getters["auth/user"]);

    const clientUser = new WebSocket(
      "wss://user.cureline-crm.cureline.com/ap/" +
        localStorage.getItem("authTokenUser") +
        "/" +
        user.value.procurement_site_id
    );

    clientUser.onmessage = function (event) {
      store
        .dispatch("globalMessenger/showMessage", JSON.parse(event.data))
        .then(() => scrollToEnd());
    };

    onMounted(() => scrollToEnd());
    onUpdated(() => scrollToEnd());

    const send = async () => {
      let msg = {
        content: message.value,
        user_id: user.value.id,

        user: {
          id: user.value.id,
          avatar: user.value.avatar,
          name: user.value.name,
        },
        created_at: new Date().toISOString(),
        admin_user: null,
      };
      clientUser.send(JSON.stringify(msg));

      await store.dispatch("globalMessenger/sendMessage", msg);
      message.value = "";
      scrollToEnd();
    };

    const getTime = (date) => {
      const formatDate = new Date(date);
      let hours = formatDate.getHours();
      let minutes = formatDate.getMinutes();
      let month = formatDate.getMonth();
      month++;
      let day = formatDate.getDate();

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return `${day}.${month} ${hours}:${minutes}`;
    };

    const scrollToEnd = () => {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight + 100;
    };

    return {
      chatting,
      user,
      chatContainer,
      message,
      send,
      getTime,
      scrollToEnd,
    };
  },
};
</script>

<style lang="scss" scoped>
.mt {
  // max-width: 1000px;
  flex: 1;
  // height: calc(100vh - 185px);
  margin-right: 16px;
  background-color: var(--col-bg-secondary);

  &-head {
    font-family: "OpenSans-Regular";
    position: relative;
    // margin-bottom: 20px;
    padding: 3px 0;
    border-radius: 4px;
    font-size: 20px;
    text-align: center;
    background-color: var(--col-info);
    color: var(--col-contrast-text);
  }

  &-body {
    padding: 0 30px;
  }
}

.chat {
  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--col-info-bg);
  }

  &-window {
    padding-top: 20px;
    padding-bottom: 10px;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: stretch;
    // padding-top: 20px;

    &-empty-img {
      max-width: 20%;
      min-width: 100px;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  &-scroller {
    min-height: 280px;
    height: calc(100vh - 300px);
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 10px;
  }

  &-container {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  &-avatar {
    position: relative;
    top: 10px;
    min-width: 36px;
    height: 36px;
    flex-basis: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    color: var(--col-info);
    background-color: var(--col-info-bg);
    overflow: hidden;
  }

  &-text {
    flex-grow: 1;
    max-width: 75%;
    padding: 0 20px 10px;

    &-details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-name {
        font-size: 16px;
        margin: 0 10px;
      }

      &-date {
        font-size: 12px;
        margin: 0 10px;
      }
    }

    &-area {
      // display: inline-block;
      padding: 5px 15px;
      border: 1px solid var(--col-info-bg);
      border-radius: 8px;
      font-size: 18px;
      line-height: 24px;
      background-color: var(--col-info-bg);
    }
  }

  &-user {
    &.chat-container {
      justify-content: flex-end;
    }

    .chat-text {
      // display: flex;
      // flex-direction: column;
      // align-items: flex-end;
      // padding-left: 50px;
      flex-grow: 0;
      text-align: right;

      // &-details {
      //   &-name {
      //     padding-left: 10px;
      //   }
      // }

      &-area {
        display: inline-block;
        text-align: left;
        background-color: var(--col-contrast-text);
      }
    }
  }

  &-admin {
    .chat-text {
      padding-right: 50px;

      display: inline-block;
      position: relative;
      flex-grow: unset;
      // padding-top: 25px;

      // &-details {
      //   position: absolute;
      //   top: 2px;
      //   left: 10px;
      // }
    }
  }

  &-action {
    position: relative;
    height: 40px;
  }

  &-textarea {
    width: 100%;
    height: 100%;
    margin: 0;
    border: 1px solid #c8c8cc;
    border-radius: 17px;
    padding: 5px 40px 5px 15px;
    line-height: 1.2;
    color: var(--col-primary-dark);
    resize: none;
    overflow: auto;
  }

  &-btn {
    position: absolute;
    right: 4px;
    bottom: 3px;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 34px;
    height: 34px;
    padding: 4px 3px;
    border: none;
    border-radius: 50%;
    background-color: var(--col-info-bg);
    cursor: unset;

    &.active {
      background-color: var(--col-info);
      cursor: pointer;
    }
  }
}
</style>
